
import React from 'react';
import './cookie-consent.less';


export default class CookieConsent extends React.Component {

    constructor(props, context) {
        super(props, context);

        const consent = !!localStorage.getItem('cookie-consent');

        if(consent){
            window.dataLayer = window.dataLayer || [];
            gtag('js', new Date());
            gtag('config', 'G-HHYWKW8Q8M');
        }

        this.state = {
            showConsent: !consent,
            modalDrop: false
        }
    }

    componentDidMount() {

        setTimeout(
            ()=>{
                this.setState({modalDrop: true})
            },500
        )

    }

    acceptCookies(accept){

        if(accept){

            window.dataLayer = window.dataLayer || [];
            gtag('js', new Date());
            gtag('config', 'G-HHYWKW8Q8M');

        }

        localStorage.setItem('cookie-consent', 'true');
        this.setState({showConsent: false})

    }

    render() {

        if(!this.state.showConsent){
            return null;
        }

        return (
            <div className='cookie-consent-background'>

                <div className={this.state.modalDrop ? 'cookie-modal do-drop' : 'cookie-modal'}>

                    <h3>Ah cookies</h3>

                    <p className='cookie-blurb'>
                        This website uses cookies for analytics (Google Analytics). Please
                        indicate your cookie preference below.
                    </p>

                    <div className='cookie-choice'>

                        <button className='btn btn-default' onClick={()=>{ this.acceptCookies(false) }}>No cookies for me thanks</button>
                        <button className='btn btn-black' onClick={()=>{ this.acceptCookies(true)}}>Yay give me cookies</button>

                    </div>

                </div>

            </div>
        )

    }

}

