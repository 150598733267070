import NavMain from '../../components/NavMain';

import React, {useState, useEffect} from 'react';
import ParallaxHeader from '../../components/ParallaxHeader';
import StickyFooter from '../../components/StickyFooter';
import CookieConsent from '../../components/CookieConsent';

import './index.less';

export default function Home() {

    const [offset, setOffset] = useState(0);

//     console.clear();
//     console.log('Hello fellow dev or adventurous internet user, welcome to my blog!');
//     console.log('I designed this myself and implemented in next.js whereupon I became frustrated with ' +
//         'their routing / nav system / general overhead so I just redid the whole thing in React.');
//     console.log("It's pretty cool: I can create pages with one component and an html file; nested paging (e.g. the blog), etc. " +
//         "There are no external libraries in use beyond react, less, webpack and babel; it's hosted on an S3 bucket.");
//     console.log("Anyway hope you're well, here's a cow.");
//     console.log(`%c ________________________________________
// < mooooooooooooooooooooooooooooooooooooo >
//  ----------------------------------------
//         \\   ^__^
//          \\  (oo)\\_______
//             (__)\\       )\\/\\
//                 ||----w |
//                 ||     ||`, "font-family:monospace");



    // store y offset for descendant pages
    useEffect(() => {

        function handleScroll() {
            setOffset(window.pageYOffset);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [offset]);

    return [
        <ParallaxHeader key='parallax-header' offset={offset}/>,
        <CookieConsent key='cookie-consent' />,
        <div key='main' className='content-body'>
            <header>
                <NavMain offset={offset}/>
            </header>
            <h1 className='main-heading'>James Drummond</h1>
            <div className='content-main'>

                <div className='home-content'>

                    <div className='welcome-blurb'>

                        <h2>
                            Tech, Startups, Lifestyle
                        </h2>

                        <p className='bio-blurb'>
                            My name is James, I'm a tech founder and senior full stack Javascript developer out of
                            London (now more commonly
                            Thailand, Bali and Vietnam). Passionate about creating the best life for me and the people
                            around me.
                        </p>

                        <div className='social-links'>

                            <a className='social-link' target='_blank' href='https://twitter.com/yellowmelondev'>
                                <img src='https://app.smoothbook.co/img/logos/twitter-logo.png'/>
                            </a>

                            <a className='social-link' target='_blank' href='https://www.linkedin.com/in/yellowmelon/'>
                                <img src='https://app.smoothbook.co/img/logos/linkedin-logo.png'/>
                            </a>

                        </div>

                    </div>

                </div>

            </div>

            <StickyFooter/>

        </div>
    ]
}
