
import React from 'react';
import {withRouter, Route, Switch} from 'react-router-dom';

import Home from './pages/home';
import Blog from './pages/blog';
import About from './pages/about';
import Contact from './pages/contact';
import WelcomeToStudymelon from './pages/blog/welcome-to-studymelon';
import PrivacyPolicy from './pages/privacy';
import TermsAndConditions from './pages/terms';

import './styles/less/base-style.less';

class App extends React.Component{

    constructor(props){
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        try{
            gtag && gtag('config', 'UA-100052204-1', {'page_path': this.props.location.pathname});
        }catch(err){
            console.error(`Analytics error: ${err.message}`);
        }

    }

    render() {
        return(

            <Switch>
                <Route exact key='home' path='/' component={Home} />
                <Route exact key='blog' path='/blog' component={Blog} />
                <Route exact key='about' path='/about' component={About} />
                {/*<Route exact key='contact' path='/contact' component={Contact} />*/}
                <Route exact key='welcome-to-studymelon' path='/blog/welcome-to-studymelon' component={WelcomeToStudymelon} />
                <Route exact key='privacy-policy' path='/privacy-policy' component={PrivacyPolicy} />
                <Route exact key='terms' path='/terms-and-conditions' component={TermsAndConditions} />
            </Switch>

        )
    }

}

export default withRouter(App);
