
import React, {useState, useEffect} from 'react';

import './nav-main.less';
import {Link, withRouter } from 'react-router-dom'

const NavMain = props => {

    const path = props.location && props.location.pathname || '/';

    return(
        <div className={`nav-main ${props.offset > 100 ? 'header-small' : ''}`}>

            <div className='logo-container'>
                <a href='/'>
                    <img className="logo" src='/img/logo.png'/>
                </a>
            </div>

            <div className='nav-links'>

                <div className='links-container'>

                    <nav className='menu-main'>

                        <input className='nav-mobile-control' type="checkbox" />
                        <span className='nav-mobile-control'></span>
                        <span className='nav-mobile-control'></span>
                        <span className='nav-mobile-control'></span>

                        <ul className='menu-items'>
                            <li>
                                <Link className={path === '/' && 'active' || ''} to='/'>Home</Link>
                            </li>
                            <li>
                                <Link className={path === '/about' && 'active' || ''} to='/about'>About</Link>
                            </li>
                            <li>
                                <Link className={path === '/contact' && 'active' || ''} to='/contact'>Contact</Link>
                            </li>
                            {/*<li>
                                <Link className={path === '/blog' && 'active' || ''} to='/blog'>Blog</Link>
                            </li>*/}
                        </ul>

                    </nav>


                </div>

            </div>

        </div>
    )
};

export default withRouter(NavMain)
