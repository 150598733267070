
import React from 'react';
import BlogPost from '../../components/BlogPost';
import postContent from './about.html';

export default function About(){

    const content = {
        name: '',
        date: '',
        title: 'About',
        content: postContent
    };

    return <BlogPost {...content} />

}
