
import React from 'react';
import {Link} from "react-router-dom";
import './sticky-footer.less';

export default function StickyFooter() {

    return(
        <div className='sticky-footer'>

            <a href='mailto:hello@studymelon.com'>
                james@yellowmelon.co
            </a>

        </div>
    )

}
